.boxLogo {
    width: 30%;
    background: #dfdfdf;
    float: left;
    text-align: center;
    height: 100vh;
    padding-top: 115px;
    margin-top: -68px;
}

.boxLogo img {
    width: 100%;
    max-width: 242px;
}

.boxLogo h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin {
    width: 70%;
    float: left;
    padding: 65px;
    
}

.contentLogin h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin form {
    width: 260px;
}

label.inputFloating {
    margin: 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;
}
    
label.inputFloating span {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    color: #bdbdbd;
    font-size: 15px;
}
  
  label.inputFloating input {
      width: 100%;
    padding: 7px;
    border: none;
    border-bottom: 1px solid #979797;
}
  
  label.inputFloating input:focus + span, label.inputFloating input:not(:placeholder-shown) + span {
    opacity:1;
    transform: scale(0.75) translateY(-80%) translateX(-20px);
  }
  
.contentLogin a, .contentLogin a:hover, .contentLogin a:focus {
    font-size: 12px;
    color: #000;
    letter-spacing: 1.58px;
    display: table;
    margin-top: 5px;
    margin-bottom: 20px;
    text-decoration: none;
}

.contentLogin .btnYellow {
    width: 100%;
    text-align: left;
    padding-left: 12px;
}

@media screen and (max-width: 768px) {
    .boxLogo {
        width: 100%;
        background: #fff;
        float: left;
        text-align: center;
        height: auto;
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .boxLogo img {
        max-width: 125px;
    }
    .contentLogin {
        margin: 40px auto 0;
        padding: 0;
        float: none;
    }
    .contentLogin form {
        margin: 0 auto;
    }
    .contentLogin h3 {
        display: none;
    }
}