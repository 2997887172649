header {
    padding: 5px 0;
    background: #6a6a6a;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 2px 2px 6px 4px rgb(0 0 0 / 21%);
}

header img {
    width: 100%;
    max-width: 124px;
}

header button {
    background: transparent;
    border: none;
    color: #fff;
}

header button img {
    float: left;
}

header button p {
    float: right;
    margin-top: 18px;
    margin-bottom: 0;
}

#userHeader {
    width: 100%;
    max-width: 31px;
    margin-right: 10px;
    margin-top: 14px;
}

img#logoutHeader {
    max-width: 27px;
    margin-right: 10px;
    margin-top: 10px;
}

.optMenu {
    float: right;
    margin-left: 60px;
}

button.toggleMobile {
    position: absolute;
    top: 21px;
    right: 20px;
    display: none;
}

.rowToggleMobile {
    width: 33px;
    height: 3px;
    background: #fff;
    margin: 5px 0;
}

@media screen and (max-width: 768px) {
    button.toggleMobile {
        display: block;
    }
    .optMenu {
        display: none;
    }
}