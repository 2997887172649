.modal-dialog {
    width: 441px;
    height: 224px;
}

.modal-box h3, .modal-box p {
    text-align: center;
    margin-top: 28px;
    color: #979797;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin: 50px;
}