#mainMenu {
    background: #dfdfdf;
    width: 265px;
    height: 100vh;
    position: fixed;
    left: 0;
    padding: 25px 0;
    overflow-y: auto;
    padding-bottom: 60px;
}

#mainMenu ul a:hover {
    text-decoration: none;
}

#mainMenu ul a li {
    padding: 20px 25px 0 25px;
    height: 60px;
}

#mainMenu ul a li.active {
    background-color: #FFF;
}

#mainMenu ul a.active li {
    background-color: #FFF;
}

#mainMenu ul a li hr {
    border-top: 1px solid #979797;
    margin-top: 16px;
}

#mainMenu ul a li img {
    width: 100%;
    max-width: 25px;
    float: left;
    margin-right: 10px;
    margin-top: -5px;
}

#mainMenu ul a li p {
    color: #6A6A6A;
    font-size: 16px;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    #mainMenu {
        width: 100%;
        height: 100vh;
        position: fixed;
        padding: 25px 0;
        z-index: 9;
        display: none;
    }
}