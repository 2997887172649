.btnNewLocation {
    background: #F2B100;
    width: 220px;
    height: 38px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1.58px;
    font-weight: 500;
    margin: 15px 0;
}