.crop-container {
    padding: 20px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.MuiSlider-root {
    width: 250px !important;
}

.reactEasyCrop_Container {
    margin: 0 auto;
}