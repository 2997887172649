.btnYellow {
    background: #F2B100;
    width: 114px;
    height: 38px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1.58px;
    font-weight: 500;
}

img.imgActive {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
}

img.imgEdit {
    width: 100%;
    max-width: 21px;
}

img.imgDelete {
    width: 100%;
    max-width: 17px;
}