@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
body {
    padding-top: 68px;
}

* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

ul li {
    list-style: none;
}

.content {
    padding-left: 275px;
    padding-top: 21px;
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px;
    }
}

.titlePages {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #979797;
    padding-bottom: 21px;
}

.titlePages h5 {
    font-size: 24px;
    color: #6a6a6a;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 700;
}

.formSearch {
    position: relative;
    margin: 20px 0;
}

.formSearch .form-control {
    padding-left: 40px;
}

.formSearch button {
    background: transparent;
    border: none;
    position: absolute;
    top: 2px;
    left: 2px;
    outline: none;
}

.table {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: none;
    border-top: none;
    background: #EAEAEA;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
}

.tableLeft {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .col-title {
        padding-left: 0;
        padding-right: 0;
    }
    .content {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 70px;
    }
    .titlePages {
        padding-bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 99;
        border-bottom: none;
    }
    .titlePages h5 {
        margin-top: 0;
        margin-bottom: 15px;
        background-color: #A9A9A9;
        color: #FFF;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1;
        padding-top: 14px;
    }
    .formSearch {
        padding: 0 25px;
    }
    .formSearch .form-group {
        position: relative;
    }
}

.thumbnail {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.load {
    background: rgba(223, 223, 223, .3);
    position: fixed;
    height: 100%;
    width: 100%;
    margin: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 10;
}

.load-table {
    background: rgba(255, 255, 255, .6);
    position: absolute;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 10;
}

.loading {
    width: 100px;
    height: 100px;
    border: 10px solid #F2994A;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.imgActive, .imgEdit, .imgDelete {
    cursor: pointer;
}

input[type=checkbox] {
    -ms-transform: scale(1.3);
    /* IE */
    -moz-transform: scale(1.3);
    /* FF */
    -webkit-transform: scale(1.3);
    /* Safari and Chrome */
    -o-transform: scale(1.3);
    /* Opera */
    transform: scale(1.3);
}

.modal-box {
    position: relative;
}

button.btnBorder {
    color: #F2994A;
    background: #fff;
    border: 1px solid #F2994A;
    width: 114px;
    height: 38px;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 1.58px;
    font-weight: 500;
}

.formNew {
    margin: 20px 0;
}

.formNew label {
    font-size: 14px;
    margin-bottom: 2px;
}

.subTitlePages {
    border-bottom: 1px solid #979797;
    padding-bottom: 12px;
}

.subTitlePages h5 {
    font-size: 18px;
    color: #6a6a6a;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 8px;
}

table.showFor.table {
    margin: 30px 0px 21px;
}

hr.hrBorder {
    border-top: 1px solid #979797;
    margin-top: 10px;
}

button.btnYellow.btnFloat {
    float: left;
    margin-right: 45px;
    margin-bottom: 30px;
    margin-top: 15px;
}

form.formShoot {
    margin-top: 22px;
    display: flex;
}