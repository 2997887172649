@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap);
header {
    padding: 5px 0;
    background: #6a6a6a;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 2px 2px 6px 4px rgb(0 0 0 / 21%);
}

header img {
    width: 100%;
    max-width: 124px;
}

header button {
    background: transparent;
    border: none;
    color: #fff;
}

header button img {
    float: left;
}

header button p {
    float: right;
    margin-top: 18px;
    margin-bottom: 0;
}

#userHeader {
    width: 100%;
    max-width: 31px;
    margin-right: 10px;
    margin-top: 14px;
}

img#logoutHeader {
    max-width: 27px;
    margin-right: 10px;
    margin-top: 10px;
}

.optMenu {
    float: right;
    margin-left: 60px;
}

button.toggleMobile {
    position: absolute;
    top: 21px;
    right: 20px;
    display: none;
}

.rowToggleMobile {
    width: 33px;
    height: 3px;
    background: #fff;
    margin: 5px 0;
}

@media screen and (max-width: 768px) {
    button.toggleMobile {
        display: block;
    }
    .optMenu {
        display: none;
    }
}
.boxLogo {
    width: 30%;
    background: #dfdfdf;
    float: left;
    text-align: center;
    height: 100vh;
    padding-top: 115px;
    margin-top: -68px;
}

.boxLogo img {
    width: 100%;
    max-width: 242px;
}

.boxLogo h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin {
    width: 70%;
    float: left;
    padding: 65px;
}

.contentLogin h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin form {
    width: 260px;
}

label.inputFloating {
    margin: 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;
}

label.inputFloating span {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    color: #bdbdbd;
    font-size: 15px;
}

label.inputFloating input {
    width: 100%;
    padding: 7px;
    border: none;
    border-bottom: 1px solid #979797;
}

label.inputFloating input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: scale(0.75) translateY(-80%) translateX(-20px);
}

label.inputFloating input:focus+span, label.inputFloating input:not(:placeholder-shown)+span {
    opacity: 1;
    -webkit-transform: scale(0.75) translateY(-80%) translateX(-20px);
            transform: scale(0.75) translateY(-80%) translateX(-20px);
}

.contentLogin a, .contentLogin a:hover, .contentLogin a:focus {
    font-size: 12px;
    color: #000;
    letter-spacing: 1.58px;
    display: table;
    margin-top: 5px;
    margin-bottom: 20px;
    text-decoration: none;
}

.contentLogin .btnYellow {
    width: 100%;
    text-align: left;
    padding-left: 12px;
}

.contentLogin .btnYellow:disabled {
    opacity: 0.8;
    cursor: progress;
}

@media screen and (max-width: 768px) {
    .boxLogo {
        width: 100%;
        background: #fff;
        float: left;
        text-align: center;
        height: auto;
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .boxLogo img {
        max-width: 125px;
    }
    .contentLogin {
        margin: 40px auto 0;
        padding: 0;
        float: none;
    }
    .contentLogin form {
        margin: 0 auto;
    }
    .contentLogin h3 {
        display: none;
    }
}
#mainMenu {
    background: #dfdfdf;
    width: 265px;
    height: 100vh;
    position: fixed;
    left: 0;
    padding: 25px 0;
    overflow-y: auto;
    padding-bottom: 60px;
}

#mainMenu ul a:hover {
    text-decoration: none;
}

#mainMenu ul a li {
    padding: 20px 25px 0 25px;
    height: 60px;
}

#mainMenu ul a li.active {
    background-color: #FFF;
}

#mainMenu ul a.active li {
    background-color: #FFF;
}

#mainMenu ul a li hr {
    border-top: 1px solid #979797;
    margin-top: 16px;
}

#mainMenu ul a li img {
    width: 100%;
    max-width: 25px;
    float: left;
    margin-right: 10px;
    margin-top: -5px;
}

#mainMenu ul a li p {
    color: #6A6A6A;
    font-size: 16px;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    #mainMenu {
        width: 100%;
        height: 100vh;
        position: fixed;
        padding: 25px 0;
        z-index: 9;
        display: none;
    }
}
.modal-dialog {
    width: 441px;
    height: 224px;
}

.modal-box h3, .modal-box p {
    text-align: center;
    margin-top: 28px;
    color: #979797;
}

.modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin: 50px;
}
.btnYellow {
    background: #F2B100;
    width: 114px;
    height: 38px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1.58px;
    font-weight: 500;
}

img.imgActive {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
}

img.imgEdit {
    width: 100%;
    max-width: 21px;
}

img.imgDelete {
    width: 100%;
    max-width: 17px;
}
.crop-container {
    padding: 20px;
    width: 100%;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.MuiSlider-root {
    width: 250px !important;
}

.reactEasyCrop_Container {
    margin: 0 auto;
}





.planFree {
    color: #3EB0C9;
}

.pendingStatus {
    color: red;
    text-transform: uppercase;
    font-size: 12px;
}

.tableDisabled {
    color: #979797;
}
.planFree {
    color: #3EB0C9;
}
.btnNewLocation {
    background: #F2B100;
    width: 220px;
    height: 38px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1.58px;
    font-weight: 500;
    margin: 15px 0;
}


img.imgSend {
    width: 100%;
    max-width: 22px;
    cursor: pointer;
}

.boxLogo {
    width: 30%;
    background: #dfdfdf;
    float: left;
    text-align: center;
    height: 100vh;
    padding-top: 115px;
    margin-top: -68px;
}

.boxLogo img {
    width: 100%;
    max-width: 242px;
}

.boxLogo h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin {
    width: 70%;
    float: left;
    padding: 65px;
    
}

.contentLogin h3 {
    font-size: 24px;
    letter-spacing: 1px;
    color: #6a6a6a;
    margin-top: 10px;
}

.contentLogin form {
    width: 260px;
}

label.inputFloating {
    margin: 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;
}
    
label.inputFloating span {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    color: #bdbdbd;
    font-size: 15px;
}
  
  label.inputFloating input {
      width: 100%;
    padding: 7px;
    border: none;
    border-bottom: 1px solid #979797;
}
  
  label.inputFloating input:not(:-ms-input-placeholder) + span {
    opacity:1;
    transform: scale(0.75) translateY(-80%) translateX(-20px);
  }
  
  label.inputFloating input:focus + span, label.inputFloating input:not(:placeholder-shown) + span {
    opacity:1;
    -webkit-transform: scale(0.75) translateY(-80%) translateX(-20px);
            transform: scale(0.75) translateY(-80%) translateX(-20px);
  }
  
.contentLogin a, .contentLogin a:hover, .contentLogin a:focus {
    font-size: 12px;
    color: #000;
    letter-spacing: 1.58px;
    display: table;
    margin-top: 5px;
    margin-bottom: 20px;
    text-decoration: none;
}

.contentLogin .btnYellow {
    width: 100%;
    text-align: left;
    padding-left: 12px;
}

@media screen and (max-width: 768px) {
    .boxLogo {
        width: 100%;
        background: #fff;
        float: left;
        text-align: center;
        height: auto;
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .boxLogo img {
        max-width: 125px;
    }
    .contentLogin {
        margin: 40px auto 0;
        padding: 0;
        float: none;
    }
    .contentLogin form {
        margin: 0 auto;
    }
    .contentLogin h3 {
        display: none;
    }
}
body {
    padding-top: 68px;
}

* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

ul li {
    list-style: none;
}

.content {
    padding-left: 275px;
    padding-top: 21px;
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px;
    }
}

.titlePages {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #979797;
    padding-bottom: 21px;
}

.titlePages h5 {
    font-size: 24px;
    color: #6a6a6a;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 700;
}

.formSearch {
    position: relative;
    margin: 20px 0;
}

.formSearch .form-control {
    padding-left: 40px;
}

.formSearch button {
    background: transparent;
    border: none;
    position: absolute;
    top: 2px;
    left: 2px;
    outline: none;
}

.table {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: none;
    border-top: none;
    background: #EAEAEA;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
}

.tableLeft {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .col-title {
        padding-left: 0;
        padding-right: 0;
    }
    .content {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 70px;
    }
    .titlePages {
        padding-bottom: 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        z-index: 99;
        border-bottom: none;
    }
    .titlePages h5 {
        margin-top: 0;
        margin-bottom: 15px;
        background-color: #A9A9A9;
        color: #FFF;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1;
        padding-top: 14px;
    }
    .formSearch {
        padding: 0 25px;
    }
    .formSearch .form-group {
        position: relative;
    }
}

.thumbnail {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.load {
    background: rgba(223, 223, 223, .3);
    position: fixed;
    height: 100%;
    width: 100%;
    margin: -15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    z-index: 10;
}

.load-table {
    background: rgba(255, 255, 255, .6);
    position: absolute;
    width: 100%;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    z-index: 10;
}

.loading {
    width: 100px;
    height: 100px;
    border: 10px solid #F2994A;
    border-top-color: transparent;
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.imgActive, .imgEdit, .imgDelete {
    cursor: pointer;
}

input[type=checkbox] {
    /* IE */
    /* FF */
    -webkit-transform: scale(1.3);
    /* Safari and Chrome */
    /* Opera */
    transform: scale(1.3);
}

.modal-box {
    position: relative;
}

button.btnBorder {
    color: #F2994A;
    background: #fff;
    border: 1px solid #F2994A;
    width: 114px;
    height: 38px;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 1.58px;
    font-weight: 500;
}

.formNew {
    margin: 20px 0;
}

.formNew label {
    font-size: 14px;
    margin-bottom: 2px;
}

.subTitlePages {
    border-bottom: 1px solid #979797;
    padding-bottom: 12px;
}

.subTitlePages h5 {
    font-size: 18px;
    color: #6a6a6a;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 8px;
}

table.showFor.table {
    margin: 30px 0px 21px;
}

hr.hrBorder {
    border-top: 1px solid #979797;
    margin-top: 10px;
}

button.btnYellow.btnFloat {
    float: left;
    margin-right: 45px;
    margin-bottom: 30px;
    margin-top: 15px;
}

form.formShoot {
    margin-top: 22px;
    display: -webkit-flex;
    display: flex;
}
