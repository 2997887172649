.planFree {
    color: #3EB0C9;
}

.pendingStatus {
    color: red;
    text-transform: uppercase;
    font-size: 12px;
}

.tableDisabled {
    color: #979797;
}